.root {
  display: inline-flex;
  align-items: center;
  padding: 1rem 2rem;
  font-family: inherit;
  font-size: 0.875rem;
  line-height: 1.25em;
  text-transform: uppercase;
  background: white;

  &:disabled,
  &[aria-disabled='true'] {
    cursor: not-allowed;
    opacity: var(--disable-dim);

    &:hover {
      opacity: var(--disable-dim);
    }
  }

  &:hover,
  &:focus {
    text-decoration: none;
    opacity: var(--dim);
  }
}

.disablePadding {
  padding: 0;
}

.block {
  display: block;
  width: 100%;
}

.small {
  font-size: 0.75rem;
}

/* stylelint-disable plugin/no-unused-selectors */
.color-black {
  color: white;
  background: var(--off-black);
}

.color-white {
  color: var(--off-black);
  background-color: white;
}

.color-transparent {
  color: var(--off-black);
  background-color: transparent;
}

.adornment {
  display: flex;
  align-items: center;
}

.startAdornment {
  padding-right: 1rem;
}

.endAdornment {
  padding-left: 1rem;
}

.buttonContent {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}
