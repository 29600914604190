/**
  Global overrides of element styles.
  Don't put classes here.
*/

*,
*::after,
*::before {
  box-sizing: border-box;
}

html {
  /** 
    use 16 here no matter what size the root size is. A base of 16 is easy to work with in rems
    
    EXAMPLES
    ================================================
    0.625rem  =>    10px
    0.75rem   =>    12px
    0.875rem  =>    14px
    1rem      =>    16px
    1.125rem  =>    18px; 
    1.5rem    =>    24px; 
    ================================================
   */
  font-family: var(--font-regular);
  font-size: 14px;
  color: var(--text-darker);
  font-display: swap;
  background: var(--off-white);
}

body {
  /** set the common text-size here in rems - not using pixels here makes the root still referrable as 16px */
  /* here the base is 16. leave unchanged. */
  /* font-size: 1rem; */
  letter-spacing: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

button {
  padding: 0;
  appearance: none;
  background-color: transparent;
  border: none;

  &:not(:disabled) {
    cursor: pointer;
  }
}

a {
  font-size: 1em;
  color: inherit;
  text-decoration: none;
  transition: opacity 0.1s;

  &:hover {
    text-decoration: underline;
  }

  &:active,
  &:focus {
    text-decoration: underline;
  }
}

input {
  input[type='search']::-webkit-search-decoration,
  input[type='search']::-webkit-search-cancel-button,
  input[type='search']::-webkit-search-results-button,
  input[type='search']::-webkit-search-results-decoration {
    appearance: none;
  }
}

#nprogress {
  pointer-events: none;

  .bar {
    position: fixed;
    top: 0;
    left: 0;
    z-index: var(--z-NProgress);
    width: 100%;
    height: 4px;
    background: var(--red);
  }

  .peg {
    position: absolute;
    right: 0;
    display: block;
    width: 100px;
    height: 100%;
    opacity: 1;
    transform: rotate(3deg) translate(0, -4px);
  }
}
