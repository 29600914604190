.root {
  font-family: var(--font-regular);
  line-height: 1.5;
}

.block {
  display: block;
}
/* stylelint-disable plugin/no-unused-selectors */
.align-inherit {
  text-align: inherit;
}

.align-left {
  text-align: left;
}

.align-right {
  text-align: right;
}

.align-center {
  text-align: center;
}

.variant-tiny {
  font-size: 0.75rem;
  line-height: 1.42;
}

.variant-small {
  font-size: 0.875rem;
  line-height: 1.42;
}

.variant-mid {
  font-size: 1rem;
  line-height: 1.42;
}

.variant-heading {
  font-size: 1.125;
  line-height: 1.42;
}

.variant-big {
  font-size: 2.5rem;
  line-height: 1.19;

  @media ($phone-down) {
    font-size: 1.75rem;
  }
}

.variant-sub {
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.35;
  text-transform: uppercase;
}

.variant-button {
  line-height: unset;
}

.variant-newspaper {
  columns: var(--content-min-width) 2;
  column-gap: var(--gap);
}

.gutter-1 {
  padding-bottom: 1em;
}

.gutter-2 {
  padding-bottom: 2em;
}

.gutter-3 {
  padding-bottom: 3em;
}

/* stylelint-enable plugin/no-unused-selectors */

.pre {
  white-space: pre-wrap;
}

.color-faded {
  opacity: 0.6;
}

.color-off-white {
  color: var(--off-white);
}

.uppercase {
  text-transform: uppercase;
}
