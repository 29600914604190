/**
  This file is used solely to import fonts that are used.
  Do not import fonts anywhere else.
*/

@font-face {
  font-family: 'Futura-Medium-01';
  font-style: normal;
  font-weight: normal;
  src: url('../fonts/futuramedium/Futura-Medium-01.eot');
  src: url('../fonts/futuramedium/Futura-Medium-01.eot?#iefix') format('embedded-opentype');
}

@font-face {
  font-family: 'Futura-Medium';
  font-style: normal;
  font-weight: normal;
  src:
    url('../fonts/futuramedium/Futura-Medium.svg#Futura-Medium') format('svg'),
    url('../fonts/futuramedium/Futura-Medium.ttf') format('truetype'),
    url('../fonts/futuramedium/Futura-Medium.woff') format('woff');
}
