/* reset of choice */
@import 'reset.css';
/* font imports */
@import 'fonts.css';
/* variables put outside of this file will not be made accessible by postcss */
@import 'variables.css';
/* global overrides */
@import 'overrides.css';
/* generic styles */
@import 'generics.css';

.spacing-top-1 {
  & > * + * {
    margin-top: spacing(1);
  }
}

.spacing-top-2 {
  & > * + * {
    margin-top: spacing(2);
  }
}

.spacing-top-3 {
  & > * + * {
    margin-top: spacing(3);
  }
}

.spacing-top-4 {
  & > * + * {
    margin-top: spacing(4);
  }
}

.spacing-top-5 {
  & > * + * {
    margin-top: spacing(5);
  }
}

.spacing-top-6 {
  & > * + * {
    margin-top: spacing(6);
  }
}

.spacing-top-7 {
  & > * + * {
    margin-top: spacing(7);
  }
}

.spacing-top-8 {
  & > * + * {
    margin-top: spacing(8);
  }
}
