.root {
  position: relative;
}

.padding-extra {
  padding: spacing(9, 0);
}

.padding-regular {
  padding: spacing(3.75) 0;
}

.padding-none {
  padding: 0;
}

.background-offblack {
  color: var(--text-light);
  background: var(--off-black);
}

.background-black {
  color: var(--text-light);
  background: black;
}

.background-white {
  color: var(--off-black);
  background: white;
}

.background-red {
  color: var(--text-light);
  background: var(--red);
}

.background-offwhite {
  color: var(--off-black);
  background-color: var(--off-white);
}

.background-midgrey {
  color: var(--off-black);
  background: var(--mid-grey);
}

.background-darkgrey {
  color: var(--text-light);
  background: var(--dark-grey);
}

.background-darker {
  color: var(--text-light);
  background: var(--text-darker);
}
