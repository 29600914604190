.visible {
  opacity: 1;
  transition:
    transform 150ms ease-in-out,
    opacity 150ms ease-in-out;
  transform: translate(0, 0);
}

.hidden-x {
  opacity: 0;
  transform: translate(50%, 0);
}

.hidden-y {
  composes: hidden-x;
  transform: translate(0, 50%);
}
