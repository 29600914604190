/**
  Globally accessible variables
  PostCSS transforms these to be backwards compatible.
*/

:root {
  /* colors */
  --off-black: #191919;
  --red: #ff1300;
  --dark-grey: #242424;
  --mid-grey: #777777;
  --light-grey: #e5e5e5;
  --off-white: #f1f1f1;
  --error: var(--red);

  --loading: rgba(0, 0, 0, 0.15);

  --text-dark: #333;
  --text-darker: #111;
  --text-light: #fff;
  --text-secondary: var(--mid-grey);

  /* fonts */
  --font-regular: 'Futura-Medium', 'Futura', 'FuturaHNormal', 'Myriad Pro', 'Franklin Gothic',
    Verdana, Tahoma, Arial, 'sans-serif';
  --font-black: 'Futura-Medium', Verdana, Tahoma, Arial, sans-serif;

  /* site gutters */
  --content-min-width: 280px;
  --content-max-width: 1440px;
  --side-margin: 30px;
  --side-margin-small: 16px;

  /* spacings */
  --gap-small: 20px;
  --gap-medium: 30px;
  --gap: 40px;
  --gap-big: 50px;
  --gap-extra-big: 80px;
  --block-spacing: 3.5rem;

  /* basic font sizes */

  /* z-indexes */
  --z-over: 2000;
  --z-nav: 3000;
  --z-top-search: 3001;
  --z-cookiebar: 7000;
  --z-backdrop: 8000;
  --z-dialog: 9000;
  --z-NProgress: 9001;

  /* misc */
  --article-max-width: 870px;
  --menu-max-width: 360px;
  --nav-height: 70px;
  --dim: 0.85;
  --disable-dim: 0.5;

  /** forms etc */
  --button-base: 3rem;
  --border-color: var(--light-grey);
}
