.root {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: var(--z-cookiebar);
  display: flex;
  flex-direction: row;
  gap: spacing(0.1);
  align-items: center;
  width: 100%;
  min-width: var(--content-min-width);
  padding: spacing(1.5);

  & > * + * {
    margin-top: 0.75rem;
  }
}

.closeIcon {
  min-width: 24px;
  margin: 0;
}

.closeIcon g {
  stroke: var(--mid-grey);
  stroke-linecap: round;
  stroke-width: 3px;
  transform: scale(0.7) translateY(4px) translateX(5px);
}

.cookieText {
  text-align: center;
}

.button {
  display: inline-block;
  width: fit-content;
  padding: 10px 15px;
  border-radius: 5px;
}

.contentWrapper {
  display: flex;
  flex-grow: 1;
  flex-wrap: wrap;
  row-gap: spacing(1);
  column-gap: spacing(1.5);
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 0;
}
