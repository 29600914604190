.content {
  position: relative;
  max-width: 240px;
  padding: spacing(4);
  border-radius: 8px;
}

.open.open {
  transform: translateY(0);
}

.bg-error {
  color: var(--off-white);
  background-color: var(--red);
  opacity: 0.8;
}

.fixed {
  position: fixed;
  right: 30px;
  bottom: 30px;
  z-index: var(--z-dialog);
  transform: translateY(calc(100% + 30px));
}

.progress {
  width: 100%;
  height: 3px;
  margin-top: spacing(2);
  background-color: var(--off-white);
}

.removeAnimation {
  width: 0%;
}

.close {
  position: absolute;
  top: spacing(1);
  right: spacing(1);
}
